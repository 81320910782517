import useSWR from 'swr'
import { Spinner } from '@contentful/f36-spinner'
import { createClient } from 'contentful'
import Content from './Content'
import ReactGA from "react-ga4";
import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'


ReactGA.initialize("G-E27XB1EFSM");

const client = createClient({
  space: process.env.CONTENTFUL_SPACE_ID,
  accessToken: process.env.CONTENTFUL_DELIVERY_TOKEN,
})

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleTagManager({
      containerId: 'GTM-PKWX985H'
    })
  ]
})

/* Track a page view */
analytics.page()

const fetcher = async () => {
  try {
    const introScreenData = await client.getEntries({ content_type: 'introScreen' });
    const clientsScreenData = await client.getEntries({ content_type: 'clientsScreen' });
    const microAgenciesScreenData = await client.getEntries({ content_type: 'microAgenciesScreen' });
    const aboutScreenData = await client.getEntries({ content_type: 'aboutScreen' });
    const championsScreenData = await client.getEntries({ content_type: 'championsScreen' });
    const insightsScreenData = await client.getEntries({ content_type: 'insightsScreen' });

    return {
      introScreen: introScreenData.items[0], 
      clientsScreen: clientsScreenData.items[0],
      microAgenciesScreen: microAgenciesScreenData.items[0],
      aboutScreen: aboutScreenData.items[0],
      championsScreen: championsScreenData.items[0],
      insightsScreen: insightsScreenData.items[0]
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

function App() {
  const { data, error } = useSWR('contentful', fetcher);

  if (error) {
    console.error(error);
    return <div>Failed to load</div>;
  }

  if (!data) return <div className='makerlab-spinner-container'><Spinner size="large" /></div>;

  return (
    <main>
      <Content
        introScreen={data.introScreen} // Pass introScreen data
        clientsScreen={data.clientsScreen} // Pass clientsScreen data
        microAgenciesScreen={data.microAgenciesScreen} // Pass microAgenciesScreen data
        aboutScreen={data.aboutScreen} // Pass aboutScreen data
        championsScreen={data.championsScreen} // Pass championsScreen data
        insightsScreen={data.insightsScreen} // Pass insightsScreen data
      />
    </main>
  );
}

export default App
