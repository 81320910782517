import React, { useEffect, useState, useRef } from 'react'

const Champions = (data) => {
    const championsScreen = data.data;

    // carousel
    const [activeIndex, setActiveIndex] = useState(0);
    const [carouselPages, setCarouselPages] = useState(1);
    const [carouselItemWidth, setCarouselItemWidth] = useState(null);
    const [carouselWrapperWidth, setCarouselWrapperWidth] = useState(null);
    const [perPageItems, setPerPage] = useState(1);
    const carouselRef = useRef(null);

    const handleDotClick = (index) => {
        // setActiveIndex(index);
        scrollToIndex(index);
      };
    
      const scrollToIndex = (index) => {
        // carouselRef.current.scrollLeft = index * perPageItems * carouselItemWidth - 10;
        scrollWithEasing(carouselRef.current, index * perPageItems * carouselItemWidth - 10, 1000); // Scroll to position 1000 with 1000ms duration
      }
    
      // add easing to scroll when dots clicked
      const scrollWithEasing = (element, targetScroll, duration) => {
        const startScroll = element.scrollLeft;
        const startTime = performance.now();
      
        const easeOutQuad = (t) => t * (2 - t);
      
        const animateScroll = (currentTime) => {
          const elapsedTime = currentTime - startTime;
          const progress = Math.min(elapsedTime / duration, 1);
          const easedProgress = easeOutQuad(progress);
          const newScroll = startScroll + (targetScroll - startScroll) * easedProgress;
      
          element.scrollLeft = newScroll;
      
          if (progress < 1) {
            requestAnimationFrame(animateScroll);
          }
        };
      
        requestAnimationFrame(animateScroll);
      };
    
      const handleScroll = () => {
        // console.log("here: ", carouselRef?.current?.children[0].clientWidth)
        const scrollLeft = carouselRef.current.scrollLeft;
        // don't allow the index to go over the number of dots-1 (index 0)
        const indexCalc = parseInt(scrollLeft / carouselRef?.current?.children[0].clientWidth);
        const newIndex = (indexCalc > 1 && indexCalc >= carouselPages-1) ? carouselPages-1 : parseInt(scrollLeft / carouselRef?.current?.children[0].clientWidth);
        const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
      
        setActiveIndex(newIndex);
    
        // // If the user is at the end of the scroll, set the last dot as active
        if (Math.ceil(scrollLeft) + 10 >= maxScrollLeft) {
          setActiveIndex(carouselPages > 1 ? carouselPages - 1 : carouselPages);
        }
      };
    
      useEffect(() => {
        // carousel micro-agencies
        if (carouselItemWidth) {
          handleScroll();
        }
      }, [carouselItemWidth])
    
      // set all the info needed
      // carousel wrapper width
      // total number of pages
      const initCarousel = () => {  
        const perPage = Math.round(carouselRef.current.clientWidth / carouselRef.current.children[0].clientWidth);
        const totalItems = championsScreen.fields.makerLabChampions.length;
    
        setPerPage(perPage)
        setCarouselItemWidth(carouselRef?.current?.children[0].clientWidth);
        setCarouselWrapperWidth(carouselRef.current.clientWidth);
        setCarouselPages(Math.ceil(totalItems / perPage));
      }
    
      useEffect(() => {
        initCarousel();
    
        const handleResize = () => {
          initCarousel();
          scrollToIndex(activeIndex);
        };
    
        window.addEventListener('resize', handleResize);
        carouselRef.current.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('resize', handleResize);
          carouselRef.current.removeEventListener('scroll', handleScroll);
        };
      }, [carouselRef, carouselRef?.current?.children[0].clientWidth]); 
    
      // grab and drag carousel
      const scrollHorizontal = (el) => {
        const slider = el;
        let isDown = false;
        let startX;
        let scrollLeft;
    
        if (slider) {
          slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          });
          slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
          });
          slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('active');
          });
          slider.addEventListener('mousemove', (e) => {
            if(!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
          });
        }
      }
    
      useEffect(() => {
        const carouselWrappers = document.querySelectorAll('.makerlab-carousel-wrapper');
        carouselWrappers.forEach((element) => {
          scrollHorizontal(element)
        });
      })

  return (
    <>
        <ul className="makerlab-carousel-wrapper" ref={carouselRef}>
            {championsScreen.fields.makerLabChampions.map((item, index) => {
            return (
                <li key={index} className="makerlab-carousel-item">
                <div className="makerlab-carousel-item--bottom">
                    {item.fields?.profilePicture?.fields?.file?.url &&
                      <img src={item.fields.profilePicture.fields.file.url} alt={item.fields.profilePicture.fields.file.fileName} />
                    }

                    <span className='makerlab-carousel-item-text-column' data-dark={item.fields.textDarkMode}>
                      <span className="makerlab-carousel-item-text makerlab-carousel-item-text--bold">{item.fields.name}</span>
                      <span className="makerlab-carousel-item-text">{item.fields.title}</span>
                    </span>
                </div>
                </li>
            )
            })}
        </ul>

        {/* Navigation dots */}
        {/* <div className="makerlab-carousel-dots" data-index={activeIndex}>
            {[...Array(carouselPages)].map((_, index) => (
                <span
                key={index}
                className={`makerlab-carousel-dot ${activeIndex === index ? 'active' : ''}`}
                onClick={() => handleDotClick(index)}
                ></span>
            ))}
        </div> */}
    </>
  )
}

export default Champions;