import axios from 'axios';
import React, { useState } from 'react'
// note that you can also export the source data via CountryRegionData. It's in a deliberately concise format to 
// keep file size down
import { CountryDropdown, CountryRegionData } from 'react-country-region-selector';

const EmailRestAPI = () => {

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [message, setMessage] = useState('');
  const [wasSentSuccessfully, setWasSentSuccessfully] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [submitText, setSubmitText] = useState("Send");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // set button text
    setSubmitText("Sending...");
    setIsSending(true);

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_tp8azya';
    const templateId = 'template_czkn2a7';
    const publicKey = '7-VBs0Z92r9ZNHlwh';

    // Create an object with EmailJS service ID, template ID, Public Key, and Template params
    const data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publicKey,
      template_params: {
        from_name: name,
        from_company: company,
        from_country: country,
        from_email: email,
        to_name: 'Web Wizard',
        message: message,
      }
    };

    // Send the email using EmailJS
    try {
      const res = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
      // console.log(res.data);
      setName('');
      setCompany('');
      setEmail('');
      setMessage('');
      setCountry('');

      //
      setWasSentSuccessfully(true);

    } catch (error) {
      console.error(error);
      setIsSending(false);
      setSubmitText("Send");
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className='makerlab-emailForm' data-ty={wasSentSuccessfully}>
        {/* input group */}
        <div className='makerlab-emailForm-group'>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            disabled={isSending}
          />
          <input
            type="text"
            placeholder="Company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            disabled={isSending}
          />
        </div>

        {/* input group */}
        <div className='makerlab-emailForm-group'>
          <input
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isSending}
          />
          <div className='selectwrapper'>
            <CountryDropdown
              value={country}
              onChange={(val) => setCountry(val)}
              disabled={isSending} />
          </div>
        </div>

        {/* group */}
        <textarea
          cols="30"
          rows="2"
          placeholder="Tell us more..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
          disabled={isSending}
        >
        </textarea>
        <button type="submit">{!wasSentSuccessfully ? submitText : "Thank you for your submission"}</button>
      </form>
    </div>
  )
}

export default EmailRestAPI